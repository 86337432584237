import { policyList } from "@/enums/permissions";
import { EventBus, store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  Callback,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
import Vue from "vue";
import { batchTypeService } from "./batchType.service";

class BatchTypeActionService extends Vue {
  public getGeneralActions(
    csvCallback: () => void,
    typeModal: Callback,
    filtersCallback: () => void
  ): ActionSubheader[] {
    return [
      {
        icon: "fal fa-file-csv",
        action: csvCallback,
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.importExportBatchTypes
          )
      },
      {
        icon: "fal fa-plus",
        action: () => {
          typeModal();
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.createBatchTypes
          )
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent",
          action: batchTypeService.searchEvent()
        }
      },
      {
        icon: "fal fa-filter",
        action: filtersCallback
      }
    ];
  }

  public getRowActions(edit: Callback): TableAction[] {
    return [
      {
        icon: "far fa-exclamation-triangle",
        class: "icon-warning",
        tooltip: String(i18n.t("inventory.missing_limits")),
        visibleCondition: arg => {
          return arg.item.limits[0].name === "Limit Not Assigned";
        }
      },
      {
        icon: "far fa-exclamation-triangle",
        class: "icon-error",
        tooltip: String(i18n.t("inventory.missing_state_report")),
        visibleCondition: arg => {
          return !arg.item.batch_type_state_reporting_id || !arg.item.stateType;
        }
      },
      {
        icon: "fal fa-pen",
        action: (...arg: any[]) => {
          const [batchType] = arg;
          edit(batchType);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.modifyBatchTypes
          )
      },
      {
        icon: "",
        switch: "is_active",
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBatchTypes
          ),
        modalActions: {
          modalNumber: 1,
          modalQuestion: String(i18n.t("batches.toggle_confirm")),
          modalSuccessText: String(i18n.t("yes")),
          modalSuccessAction: async (arg: TableSuccessModalResponse) => {
            try {
              await batchTypeService.toggleBatchType(arg);
              store
                .dispatch("BatchTypeModule/loadBatchTypes")
                .finally(arg.unselectModal);
            } catch (e) {
              EventBus.$emit("unSelectedModalActive");
              arg.unselectModal();
            }
          },
          modalCancelText: String(i18n.t("no"))
        }
      }
    ];
  }

  public getMultiActions(): TableAction[] {
    return [
      {
        icon: "fal fa-eye",
        action(args: any) {
          store.dispatch("BatchTypeModule/enableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBatchTypes
          )
      },
      {
        icon: "fal fa-eye-slash",
        action(args: any) {
          store.dispatch("BatchTypeModule/disableMultiple", args);
        },
        visibleCondition: () =>
          store.getters["PermissionsModule/hasPermission"](
            policyList.enableDisableBatchTypes
          )
      }
    ];
  }
}

export const batchTypeActionService = new BatchTypeActionService();
