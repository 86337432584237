export interface BatchType {
  account_id: number;
  id?: string | null;
  name: string;
  limits: Array<{
    name: string;
  }>;
  sales_limit_exempt: boolean;
  sales_limit_unassigned: boolean;
  batch_type_state_reporting_id: number | null;
  updated_at?: string;
  is_active: boolean;
  rule_set_id?: number | null;
}

export const defaultBatchType = {
  account_id: 0,
  id: null,
  limits: [],
  name: "",
  sales_limit_exempt: false,
  sales_limit_unassigned: true,
  location_enabled: false,
  batch_type_state_reporting_id: null,
  is_active: false
};

export interface BatchTypeToSave {
  account_id: number;
  name: string;
  sales_limit_exempt: boolean | null;
  location_enabled: boolean | null;
}

export interface StateReportingBatchType {
  id: number;
  name: string;
  state_id: number;
}
