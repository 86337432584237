import { policyList } from "@/enums/permissions";
import { BatchType, StateReportingBatchType } from "@/interfaces/batchType";
import { store } from "@/internal";
import { batchTypeService } from "@/services/batchType.service";
import { messagesService } from "@/services/messages.service";
import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import Component from "vue-class-component";
import { Inject, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BatchTypeModal.template.vue";

@Component({
  mixins: [Template]
})
export class BatchTypeModalComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;
  @Prop({ required: true }) public item!: BatchType;
  @Prop({ default: () => [] })
  public stateReportingBatchTypes!: StateReportingBatchType[];
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public salesLimitOptions = false;
  public isSaving = false;
  public isAllowed = false;
  public model: BatchType | null = null;

  public close() {
    this.$emit("resolve");
  }

  public cancel() {
    this.$emit("reject");
  }

  public goLimits() {
    store.dispatch("RouterModule/go", {
      name: "sales-limit"
    });
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      try {
        this.isSaving = true;
        if (!this.isAllowed) {
          delete this.model!.sales_limit_exempt;
          delete this.model!.limits;
        }
        await batchTypeService.save(this.model!);
        messagesService.renderSuccessMessage("batch_type_saved");
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        this.isSaving = false;

        if (!this.isAllowed) {
          this.close();
        } else {
          if (this.salesLimitOptions) {
            this.close();
          } else {
            this.salesLimitOptions = true;
          }
        }
      }
    }
  }

  protected mounted() {
    this.isAllowed = this.hasPermission(policyList.modifySalesLimits);
    this.model = cloneDeep(this.item);
  }
}
