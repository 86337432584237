import { BatchType } from "@/interfaces/batchType";
import { LimitConfig } from "@/interfaces/location";
import { BatchTypeState } from "@/vuex/modules/inventory/batchType/batchType.types";
import { TablePagination } from "helix-vue-components";
import { MutationTree } from "vuex";

type TypeMutationTree = MutationTree<BatchTypeState>;

export const mutations: TypeMutationTree = {
  setBatchTypes(state: BatchTypeState, batchTypes: BatchType[]) {
    state.batchTypes = batchTypes;
  },
  setLoading(state: BatchTypeState, loading: boolean) {
    state.loading = loading;
  },
  setPagination(state: BatchTypeState, pagination: TablePagination | null) {
    state.pagination = pagination;
  },
  setBatchType(state: BatchTypeState, batchType: BatchType) {
    state.batchType = batchType;
  }
};
