import { BatchType } from "@/interfaces/batchType";
import { LimitConfig } from "@/interfaces/location";
import { BatchTypeState } from "@/vuex/modules/inventory/batchType/batchType.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { GetterTree } from "vuex";

type BatchTypeGetter = GetterTree<BatchTypeState, RootState>;

export const getters: BatchTypeGetter = {
  batchTypes(state: BatchTypeState): BatchType[] | null {
    return state.batchTypes;
  },
  pagination(state: BatchTypeState): TablePagination | null {
    return state.pagination;
  },
  loading(state: BatchTypeState): boolean {
    return state.loading;
  },
  batchType(state: BatchTypeState): BatchType | null {
    return state.batchType;
  }
};
