import { policyList } from "@/enums/permissions";
import { BatchType, BatchTypeToSave } from "@/interfaces/batchType";
import { LocationSalesLimits } from "@/interfaces/location";
import { batchTypeService } from "@/services/batchType.service";
import { limitsService } from "@/services/limits.service";
import { messagesService } from "@/services/messages.service";
import { BatchTypeState } from "@/vuex/modules/inventory/batchType/batchType.types";
import { RootState } from "@/vuex/types";
import { TablePagination } from "helix-vue-components";
import { ActionContext, ActionTree } from "vuex";

type BatchTypeActionContext = ActionContext<BatchTypeState, RootState>;
type BatchTypeActionTree = ActionTree<BatchTypeState, RootState>;

export const actions: BatchTypeActionTree = {
  async loadBatchTypes(context: BatchTypeActionContext): Promise<any> {
    try {
      context.commit("setLoading", true);
      let batchTypes = await batchTypeService.get();
      const pagination: TablePagination = await batchTypeService.getPagination();
      const limits: LocationSalesLimits[] =
        context.rootGetters["AuthModule/limitConfig"];
      batchTypes = batchTypes.map(batchType => {
        batchType.limits = [];
        if (limits) {
          limits.forEach(rule => {
            if (
              rule.strategy_config.batch_types_exempted &&
              rule.strategy_config.batch_types_exempted.includes(
                String(batchType.id)
              )
            ) {
              batchType.limits = [{ name: "Limit Exempted" }];
            } else {
              const ruleLimits = rule.configs.reduce(
                (acc: Array<{ name: string }>, c) => {
                  if (
                    c.config.batch_types &&
                    c.config.batch_types.includes(batchType.id!)
                  ) {
                    acc.push({
                      name:
                        rule.strategy === "multiple_overall_limits"
                          ? c.name
                          : rule.strategy ===
                            "one_daily_limit_with_monthly_overall"
                          ? "One Daily Limit With Monthly Overall Limit"
                          : "Single Overall Limit"
                    });
                  }
                  return acc;
                },
                []
              );
              batchType.limits = [...batchType.limits, ...ruleLimits];
            }
          });
        }
        if (!batchType.limits.length) {
          batchType.limits = [{ name: "Limit Not Assigned" }];
        }
        return batchType;
      });
      context.commit("setPagination", pagination);
      context.commit("setBatchTypes", batchTypes);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },
  async searchToBatchTypeList(
    context: BatchTypeActionContext,
    value: string
  ): Promise<any> {
    try {
      const search = batchTypeService.searchEvent();
      search(value);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async filterBatchTypes(
    context: BatchTypeActionContext,
    filters: object
  ): Promise<any> {
    try {
      batchTypeService.sortQuery(filters);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async saveBatchType(
    context: BatchTypeActionContext,
    batchType: BatchType
  ): Promise<any> {
    try {
      batchTypeService.save(batchType).then(() => {
        context.dispatch("loadBatchTypes");
        messagesService.renderSuccessMessage("batch_type_saved");
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },
  async deleteBatchType(
    context: BatchTypeActionContext,
    batchType: BatchTypeToSave
  ): Promise<any> {
    try {
      await batchTypeService.delete(batchType, true);
      context.dispatch("loadBatchTypes");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  },

  async enableMultiple(
    context: BatchTypeActionContext,
    batchTypes: BatchType[]
  ): Promise<any> {
    const batchTypeIds = batchTypes.map(
      (batchType: BatchType) => batchType.id!
    );
    context.commit("setLoading", true);
    try {
      await batchTypeService.enableMultiple(batchTypeIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadBatchTypes");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async disableMultiple(
    context: BatchTypeActionContext,
    batchTypes: BatchType[]
  ): Promise<any> {
    const batchTypeIds = batchTypes.map(
      (batchType: BatchType) => batchType.id!
    );
    context.commit("setLoading", true);
    try {
      await batchTypeService.disableMultiple(batchTypeIds);
      messagesService.renderSuccessMessage("updated_successfully");
      context.dispatch("loadBatchTypes");
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async deleteMultiple(
    context: BatchTypeActionContext,
    batchTypes: BatchType[]
  ): Promise<any> {
    const batchTypeIds = batchTypes.map(
      (batchType: BatchType) => batchType.id!
    );
    const pinText = {
      title: "inventory.delete_batch_types_pin",
      message: "inventory.delete_batch_types_pin_message"
    };
    const pin = await batchTypeService.pinAction(pinText);
    if (pin) {
      context.commit("setLoading", true);
      try {
        await batchTypeService.disableMultiple(batchTypeIds, pin);
        messagesService.renderSuccessMessage("deleted_successfully");
        context.dispatch("loadBatchTypes");
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        context.commit("setLoading", false);
      }
    }
  },

  async loadLimits(context: BatchTypeActionContext): Promise<void> {
    const hasPermission =
      context.rootGetters["PermissionsModule/hasPermission"];
    if (hasPermission(policyList.viewSalesLimits)) {
      const limits = await limitsService.getLimitsCurrentLocation();
      context.commit("setLimits", limits[0] || null);
    }
  }
};
