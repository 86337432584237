import debounce from "lodash/debounce";
import { Component, Vue } from "vue-property-decorator";
import Template from "./BatchTypesFilter.template.vue";
@Component({
  mixins: [Template]
})
export class BatchTypesFilterComponent extends Vue {
  public loaded: boolean = true;
  public isActive = true;
  public isMissingLimits = false;
  public isStateReport = false;
  protected dFilter = debounce(
    component =>
      component.$emit("filter", {
        active: component.isActive,
        limits: component.isMissingLimits,
        stateReport: component.isStateReport
      }),
    500
  );

  public toggleActive() {
    this.isActive = !this.isActive;
    this.filter();
  }

  public toggleMissingLimits() {
    this.isMissingLimits = !this.isMissingLimits;
    this.filter();
  }
  public toggleMissingStateReport() {
    this.isStateReport = !this.isStateReport;
    this.filter();
  }

  public filter() {
    this.dFilter(this);
  }
}
