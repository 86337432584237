import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { policyList } from "@/enums/permissions";
import {
  BatchType,
  defaultBatchType,
  StateReportingBatchType
} from "@/interfaces/batchType";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Location } from "@/interfaces/location";
import { pusherEvents } from "@/internal";
import { BatchTypeTableMetadata } from "@/metadata/batchType";
import { batchTypeActionService } from "@/services/batchType.action.service";
import { batchTypeService } from "@/services/batchType.service";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { Callback, PageNavAction } from "@/types/types";
import { BatchTypeModule } from "@/vuex/modules/inventory/batchType/batchType.index";
import {
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { BatchTypeModalComponent } from "./BatchTypeModal/BatchTypeModal.component";
import Template from "./BatchTypes.template.vue";
import { BatchTypesFilterComponent } from "./BatchTypesFilter.component";

const namespace: string = "BatchTypeModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    BatchTypesFilterComponent
  },
  inject: ["$changes"]
})
export default class BatchTypesComponent extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("batchTypes", { namespace })
  public batchTypes!: BatchType[] | null;
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Mutation("setBatchTypes", { namespace })
  public batchTypesMissingLimits!: Callback;
  @Action("loadBatchTypes", { namespace })
  public getBatchTypesAction!: Callback;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public stateReportingBatchTypes: StateReportingBatchType[] = [];
  public modelToEdit: BatchType = { ...defaultBatchType };
  public modalData = { title: "", width: "500px", height: "470px" };
  public isSaving = false;
  public enableMultiActions = false;
  public headers: TableHeader[] = BatchTypeTableMetadata;
  public showFilters = false;
  public activeFilter = false;
  public generalActions: ActionSubheader[] = batchTypeActionService.getGeneralActions(
    this.openCsvModal,
    this.openTypeModal,
    this.filtersCallback
  );
  public rowActions: TableAction[] = batchTypeActionService.getRowActions(
    this.openTypeModal
  );
  public multiActions: TableAction[] = batchTypeActionService.getMultiActions();
  protected query: HttpQuery = this.setCustomerQuery();

  /**
   * Enable filter view for qualified/non qualified products.
   */
  public get batchTypesData() {
    const batchListData = this.batchTypes!.map(batchIndex => {
      const stateType = this!.stateReport!.find(
        stateIndex => stateIndex.id === batchIndex.batch_type_state_reporting_id
      );
      const batchData = Object.assign(batchIndex, {
        stateType: stateType || ""
      });
      return batchData;
    });
    return batchListData;
  }
  public get stateReport() {
    return this.stateReportingBatchTypes;
  }

  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "batch-types" }
    );
  }

  /**
   * Update requests on column name click to sort.
   * @param header: TableHader
   */
  public filter(header: TableHeader) {
    batchTypeService.sortQuery(header);
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters) {
      this.triggerSearch({ "q[is_active]": 1 });
    }
  }

  public async triggerSearch(query?: HttpQuery) {
    batchTypeService.setQuery(query);
    this.getBatchTypesAction();
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onBatchTypeFilter(filter: {
    active: boolean;
    limits: boolean;
    stateReport: boolean;
  }) {
    if (filter.active) {
      this.query["q[is_active]"] = 1;
      delete this.query["q[is_active_is_false]"];
    } else {
      this.query["q[is_active_is_false]"] = 1;
      delete this.query["q[is_active]"];
    }
    if (filter.stateReport) {
      this.query["q[missing_batch_type_state_reporting]"] = 1;
    } else {
      delete this.query["q[missing_batch_type_state_reporting]"];
    }
    if (filter.limits) {
      this.query["q[missing_limits]"] = 1;
    } else {
      delete this.query["q[missing_limits]"];
    }
    this.query.page = 1;
    this.triggerSearch(this.query);
  }

  public changePagination(pagination: TablePagination) {
    const paginate = batchTypeService.paginationAction();
    paginate(pagination);
  }

  public openTypeModal(model?: BatchType) {
    this.$modals
      .load(
        BatchTypeModalComponent,
        {
          size: "normal",
          positionY: "top"
        },
        {
          item: { ...(model || this.modelToEdit) },
          stateReportingBatchTypes: this.stateReportingBatchTypes
        }
      )
      .then(this.getBatchTypesAction)
      .catch(() => {
        // No action on close.
      });
  }

  protected async created() {
    this.getBatchTypesAction();
    await batchTypeService
      .getStateReportingBatchTypes()
      .then(data => (this.stateReportingBatchTypes = data));

    this.$changes.watch(
      pusherEvents.batchTypeTouched,
      this.getBatchTypesAction
    );

    this.headers.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }

  protected async mounted() {
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableBatchTypes
    );
    this.setPageNav({
      title: "inventory.inventory_types",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
  }

  protected beforeCreate() {
    this.$store.registerModule("BatchTypeModule", BatchTypeModule);
  }

  protected beforeDestroy() {
    this.$store.unregisterModule("BatchTypeModule");
    batchTypeService.resetQuery();
  }
  private setCustomerQuery(): HttpQuery {
    return {
      ...INITIAL_QUERY_STATE,
      "q[is_active]": 1
    };
  }
}
